<template>
  <div class="month-selector">
    <v-menu :close-on-content-click="false"   offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on"  color="primary">
          <v-icon>mdi-calendar-month</v-icon>
        </v-btn>
      </template>

      <v-card width="300">
        <v-card-text>
          <div class="d-flex justify-space-between align-center mb-4">
            <v-btn icon @click="changeYear(-1)">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <div class="text-h6">{{ currentYear }}</div>
            <v-btn icon @click="changeYear(1)">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
          <v-row dense>
            <v-col v-for="(month, index) in months" :key="index" cols="6">
              <v-btn
                text
                small
                :color="selectedMonth === index ? 'primary' : ''"
                @click="selectMonth(index)"
                :outlined="selectedMonth === index"
                block
              >
                {{ month.substring(0, 3) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>

import moment from 'moment';

export default {
  name: 'MonthSelector',
  data() {
    return {
      currentYear: moment().year(),
      selectedMonth: moment().month(),
      months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
    }
  },
  methods: {
    changeYear(delta) {
      this.currentYear += delta;
    },
    selectMonth(monthIndex) {
      this.selectedMonth = monthIndex;
      this.$emit('month-selected', {
        year: this.currentYear,
        month: monthIndex + 1
      });
    }
  }
}
</script>

<style scoped>
.month-selector {
  display: inline-block;
}
button:hover {
  background-color: #b391c2;
}
</style>