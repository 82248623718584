<template>
  <v-card :key="modalKey">
    <v-toolbar class="mb-2" color="primary" dark>
      <v-toolbar-title class="text-h5" elevation="20">
        <span class="headline"
          >Création de tâche pour {{ preselectedUser.name }}</span
        >
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="closeModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <!-- Informations de base de la tâche -->
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="libelle"
              label="Libellé de la tâche*"
              :rules="[libelleRule]"
              autofocus
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- Assignation et durée -->
        <v-row>
          <v-col cols="6">
            <v-text-field
              :value="preselectedUser.name"
              label="Assigné à"
              readonly
              outlined
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.number="dureeQuotidienne"
              label="Durée quotidienne (heures)*"
              type="number"
              min="0.5"
              max="24"
              step="0.5"
              :rules="[
                (v) => !!v || 'La durée est requise',
                (v) => v >= 0.5 || 'La durée minimale est de 0.5 heure',
                (v) => v <= 24 || 'La durée maximale est de 24 heures',
              ]"
              @input="validateDureeQuotidienne"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- Période d'affectation -->
        <v-row>
          <v-col cols="6">
            <v-menu
              ref="menuDateDebut"
              v-model="menuDateDebut"
              :close-on-content-click="false"
              :return-value.sync="dateDebut"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedDateDebut"
                  label="Date de début*"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[(v) => !!v || 'La date de début est requise']"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateDebut"
                no-title
                scrollable
                locale="fr"
                :first-day-of-week="1"
                :min="minDate"
                @input="$refs.menuDateDebut.save(dateDebut)"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              ref="menuDateFin"
              v-model="menuDateFin"
              :close-on-content-click="false"
              :return-value.sync="dateFin"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedDateFin"
                  label="Date de fin*"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[
                    (v) => !!v || 'La date de fin est requise',
                    (v) =>
                      !dateDebut ||
                      new Date(v) >= new Date(dateDebut) ||
                      'La date de fin doit être après la date de début',
                  ]"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateFin"
                no-title
                scrollable
                locale="fr"
                :first-day-of-week="1"
                :min="dateDebut || minDate"
                @input="handleDateFinChange"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <!-- Type de tâche et impact -->
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="selectedDevType"
              :items="devType"
              label="Type*"
              :rules="[devTypeRule]"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="selectedImpactType"
              :items="impactType"
              label="Type d'impact"
            ></v-select>
          </v-col>
        </v-row>

        <!-- Module et sous-module -->
        <v-row>
          <v-col cols="6">
            <v-autocomplete
              v-model="module"
              :items="dataModule"
              :item-text="(item) => `${item.module}`"
              label="Module"
              multiple
              chips
              small-chips
            ></v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              v-model="sousModule"
              :items="dataSousModule"
              item-text="sous_module"
              label="Sous-module"
              multiple
              chips
              small-chips
            ></v-autocomplete>
          </v-col>
        </v-row>

        <!-- Description avec Tiptap -->
        <v-row>
          <v-col cols="12">
            <editor
              v-model="description"
              :rules="[descriptionRule]"
              :placeholder="'Description de la tâche*'"
              @select-attachment="dialogPieceJointe = true"
            ></editor>
          </v-col>
        </v-row>

        <!-- Prévisualisation des fichiers -->
        <v-row>
          <v-col cols="12" v-if="newLogoPreviews.length > 0">
            <v-carousel cycle hide-delimiters height="200">
              <v-carousel-item
                v-for="(preview, index) in newLogoPreviews"
                :key="index"
              >
                <v-img
                  :src="preview"
                  contain
                  max-width="100%"
                  max-height="200"
                ></v-img>
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>

        <!-- Alerte de dépassement si nécessaire -->
        <v-row v-if="showOverloadWarning">
          <v-col cols="12">
            <v-alert type="warning" dense outlined>
              Attention : Cette tâche dépassera la limite quotidienne de
              {{ limiteQuotidienne }} heures pour la période sélectionnée.
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="closeModal">Annuler</v-btn>
      <v-btn
        color="success"
        text
        @click="saveTask"
        :disabled="!isValidForm || saving"
        :loading="saving"
      >
        Créer et affecter
      </v-btn>
    </v-card-actions>

    <!-- Modal pour l'ajout de pièces jointes -->
    <v-dialog v-model="dialogPieceJointe" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Ajouter une pièce jointe</span>
        </v-card-title>
        <v-card-text>
          <v-container class="d-flex flex-column">
            <v-row>
              <v-col>
                <v-file-input
                  v-model="files"
                  label="Choisir un fichier"
                  @change="handleFilesChange"
                  multiple
                  accept="image/*, application/pdf"
                  type="file"
                  show-size
                  counter
                  clearable
                  name="uploadedfiles"
                  id="uploadedfiles"
                >
                  <template #selection="{ index, text }">
                    <v-chip
                      small
                      label
                      close
                      color="primary"
                      @click:close="deleteChip(index, text)"
                    >
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row v-if="newLogoPreviews.length > 0">
              <v-col
                v-for="(preview, index) in newLogoPreviews"
                :key="index"
                style="overflow-x: auto"
              >
                <v-img
                  :src="preview"
                  contain
                  max-width="100%"
                  max-height="300"
                ></v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogPieceJointe = false">
            Annuler
          </v-btn>
          <v-btn color="success" text @click="dialogPieceJointe = false">
            Ajouter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import ModuleService from "@/Services/SupportVision/ModuleService";
import DeveloppementService from "@/Services/SupportVision/DeveloppementService";
import ParametrageService from "@/Services/SupportVision/ParametrageService";

export default {
  name: "CreateTaskWithAssignment",
  components: {
    Editor: () => import("@/Components/Commons/Inputs/Editor/Editor.vue"),
  },
  props: {
    preselectedUser: {
      type: Object,
      required: true,
      validator: function(value) {
        return value && value.name !== undefined;
      },
    },
    preselectedDateRange: {
      type: Object,
      required: true,
      validator: function(value) {
        return value && value.start && value.end;
      },
    },
    existingTasks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      modalKey: 0,
      saving: false,
      limiteQuotidienne: 7,

      // Champs du formulaire
      libelle: "",
      description: "",
      dureeQuotidienne: 0.5,
      dateDebut: null,
      dateFin: null,
      selectedDevType: "Interface/Module",
      selectedImpactType: "Travaux unique",
      module: [],
      sousModule: [],

      // Données pour les selects
      dataModule: [],
      dataSousModule: [],
      devType: [],
      impactType: ["Travaux unique", "Release", "Urgent"],

      // UI controls
      menuDateDebut: false,
      menuDateFin: false,
      showOverloadWarning: false,

      dialogPieceJointe: false,
      files: [],
      newLogoPreviews: [],
      dataFile: null,
      previousFiles: [],

      // Règles de validation
      libelleRule: (v) => !!v || "Le libellé est requis",
      descriptionRule: (v) => !!v || "La description est requise",
      devTypeRule: (v) => !!v || "Le type de développement est requis",
    };
  },
  computed: {
    ...mapState(["informations"]),

    minDate() {
      return moment().format("YYYY-MM-DD");
    },

    formattedDateDebut() {
      return this.dateDebut ? moment(this.dateDebut).format("DD/MM/YYYY") : "";
    },

    formattedDateFin() {
      return this.dateFin ? moment(this.dateFin).format("DD/MM/YYYY") : "";
    },

    isValidForm() {
      return (
        this.libelle &&
        this.description &&
        this.dureeQuotidienne >= 0.5 &&
        this.dureeQuotidienne <= 24 &&
        this.dateDebut &&
        this.dateFin &&
        this.selectedDevType
      );
    },
  },
  async mounted() {
    await this.getDevType();
  },
  async created() {
    await this.loadInitialData();
    this.initializeFromProps();
  },
  methods: {
    handleFilesChange() {
      this.files = [];
      const uploadedFiles = document.getElementById("uploadedfiles").files;
      for (let i = 0; i < uploadedFiles.length; i++) {
        if (
          this.previousFiles !== undefined &&
          this.previousFiles !== null &&
          this.previousFiles.length <= 0
        ) {
          this.previousFiles.push(uploadedFiles[i]);
        } else {
          const index = this.previousFiles.findIndex(
            (x) => x.name === uploadedFiles[i].name
          );
          if (index >= 0) {
            this.previousFiles.splice(index, 1);
          }
          this.previousFiles.push(uploadedFiles[i]);
        }
      }
      this.files = this.previousFiles;

      this.newLogoPreviews = [];
      for (let file of this.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.newLogoPreviews.push(e.target.result);
        };
        reader.readAsDataURL(file);
      }
      this.dataFile = this.files;
    },
    async getDevType() {
      const res = await ParametrageService.getParametrages();
      this.devType = res?.data.map((i) => i.parametrage.choix || "");
    },
    async loadInitialData() {
      try {
        const [modules, sousModules] = await Promise.all([
          ModuleService.getModules(),
          ModuleService.getSousModules(),
        ]);

        this.dataModule = modules.data.map((module) => module.module);
        this.dataSousModule = sousModules;
      } catch (error) {
        this.$nError("Erreur lors du chargement des données");
      }
    },

    initializeFromProps() {
      if (this.preselectedDateRange) {
        this.dateDebut = moment(this.preselectedDateRange.start).format(
          "YYYY-MM-DD"
        );
        this.dateFin = moment(this.preselectedDateRange.end).format(
          "YYYY-MM-DD"
        );
        this.validateDureeQuotidienne();
      }
    },

    validateDureeQuotidienne() {
      if (!this.dureeQuotidienne || !this.dateDebut || !this.dateFin) return;

      const start = moment(this.dateDebut);
      const end = moment(this.dateFin);
      const overlappingTasks = this.existingTasks.filter((task) => {
        const taskStart = moment(task.carte.assignement.dateDebut);
        const taskEnd = moment(task.carte.assignement.dateFin);
        return (
          task.carte.assignement.assignedTo === this.preselectedUser.name &&
          taskStart.isSameOrBefore(end) &&
          taskEnd.isSameOrAfter(start)
        );
      });

      // Calculer la charge quotidienne pour la période
      const dailyLoad = overlappingTasks.reduce((total, task) => {
        return total + (task.carte.duree ? task.carte.duree.duree : 0);
      }, 0);

      this.showOverloadWarning =
        dailyLoad + this.dureeQuotidienne > this.limiteQuotidienne;
    },

    handleDateFinChange(date) {
      this.$refs.menuDateFin.save(date);
      this.validateDureeQuotidienne();
    },

    async saveTask() {
      if (!this.isValidForm) return;
      this.saving = true;
      try {
        const formData = new FormData();
        // Construire l'objet assignement
        const assignementData = {
          ressources: {
            tracking: [],
            limiteQuotidienne: 7,
          },
          dateAssignement: moment().format("YYYY-MM-DD HH:mm:ss"),
          dateFin: moment(this.dateFin)
            .endOf("day")
            .format("YYYY-MM-DD HH:mm:ss"),
          dateDebut: moment(this.dateDebut)
            .startOf("day")
            .format("YYYY-MM-DD HH:mm:ss"),
          assignedTo: this.preselectedUser.name,
          assigned: true,
        };

        // Construire l'objet durée
        const dureeData = {
          unite: "heures",
          duree: Number(this.dureeQuotidienne),
        };

        // Ajouter tous les champs au formData
        formData.append("libelle", this.libelle);
        formData.append("description", this.description);
        formData.append(
          "userCreate",
          this.informations.firstname + " " + this.informations.lastname
        );
        formData.append("devType", this.selectedDevType);
        formData.append("impactType", this.selectedImpactType);
        formData.append("originType", this.selectedOriginType || "");
        formData.append("clientName", this.clientName || "");
        formData.append("base", this.base || "");
        formData.append("linkedCard", "");
        formData.append("urgentRequestDate", "null");
        formData.append("module", this.module || "");
        formData.append("sous_module", this.sousModule || "");
        formData.append("images", "null");

        // Convertir les objets en JSON strings
        formData.append("assignement", JSON.stringify(assignementData));
        formData.append("duree", JSON.stringify(dureeData));

        // Ajouter les fichiers s'ils existent
        if (this.dataFile && this.dataFile.length > 0) {
          for (let file of this.dataFile) {
            formData.append("file", file);
          }
        }

        const savedTask = await DeveloppementService.saveDev(formData);
        this.$emit("task-created", savedTask);
        this.$nSuccess("Tâche créée et affectée avec succès");
        this.closeModal();
      } catch (error) {
        console.error("Erreur lors de la création de la tâche:", error);
        this.$nError("Erreur lors de la création de la tâche");
      } finally {
        this.saving = false;
      }
    },

    closeModal() {
      this.reset();
      this.$emit("close");
    },

    reset() {
      this.libelle = "";
      this.description = "";
      this.dureeQuotidienne = 0.5;
      this.dateDebut = null;
      this.dateFin = null;
      this.selectedDevType = "Interface/Module";
      this.selectedImpactType = "Travaux unique";
      this.module = [];
      this.sousModule = [];
      this.showOverloadWarning = false;
      this.modalKey += 1;
      this.files = [];
      this.newLogoPreviews = [];
      this.dataFile = null;
      this.previousFiles = [];
      this.dialogPieceJointe = false;
    },
  },
  watch: {
    preselectedDateRange: {
      handler(newValue) {
        if (newValue) {
          this.dateDebut = moment(newValue.start).format("YYYY-MM-DD");
          this.dateFin = moment(newValue.end).format("YYYY-MM-DD");
          this.validateDureeQuotidienne();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.v-card {
  max-height: 90vh;
  overflow-y: auto;
}

.editor-container {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  min-height: 200px;
}
</style>
