import Api from "@/Services/Api";

export default {
    async getParametrages() {
        let response = await Api().get("/support-vision/parametrage/all")
        return response
    },
    async saveParametrage(data) {
        let response = await Api().post("/support-vision/parametrage/save", data)
        return response
    },
    async editParametrage(data) {
        let response = await Api().put("/support-vision/parametrage/edit", data)
        return response
    },
    async deleteParametrage() {
        let response = await Api().delete("/support-vision/parametrage/remove")
        return response
    }
}