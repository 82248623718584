import Api from "@/Services/Api";

export default {
    async getModules() {
        let response = await Api().get("/support-vision/modules")
        return response
    },
    async getModuleById(id) {
        let response = await Api().get(`/support-vision/modules/module/${id}`)
        return response.data
    },
    async getSousModuleById(id) {
        let response = await Api().get(`/support-vision/modules/sous-module/${id}`)
        return response.data
    },
    async getSousModules() {
        let response = await Api().get("/support-vision/modules/sous-modules")
        return response.data
    },
    async createModule(data) {
        let response = await Api().post(`/support-vision/modules/`, data)
        return response.data
    },
    async createSousModule(data) {
        let response = await Api().post(`/support-vision/modules/sous-module`, data)
        return response.data
    },
    async updateModule(id, data) {
        let response = await Api().put(`/support-vision/modules/module-update${id}`, data)
        return response
    },
    async updateSousModule(id, data) {
        let response = await Api().put(`/support-vision/modules/sous-module-update${id}`, data)
        return response
    },
    async removeModule(id) {
        let response = await Api().delete(`/support-vision/modules/${id}`)
        return response
    },
    async removeSousModule(id) {
        let response = await Api().delete(`/support-vision/modules/sous-module/${id}`)
        return response
    },
    async searchElement(search) {
        let response = await Api().get(`/support-vision/modules/search/${search}`)
        return response
    }
}