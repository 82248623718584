<template>
  <div
    class="calendrier-ressources"
    ref="calendrierRessources"
    @click="handleGlobalClick"
  >
    <header>
      <nav>
        <span class="active">Planning</span>
      </nav>
      <div class="controls">
        <div class="view-selector">
          <span class="mr-2">Vues</span>
          <select v-model="currentView" style="cursor: pointer;">
            <option value="month">Mois</option>
            <option value="week">Semaines</option>
            <option value="day">Jour</option>
          </select>
        </div>
        <button @click="goToToday">Aujourd'hui</button>
        <button @click="changeDate(-1)">&lt;</button>
        <button @click="changeDate(1)">&gt;</button>
      </div>
    </header>
    <div class="calendar-container">
      <div class="resource-names" ref="resourceNames">
        <div
          class="resource-menu"
          style="position: sticky; top: 0; z-index: 10; background-color: white;"
        >
          <!-- btn de recherche par user -->
          <v-menu :close-on-content-click="false" :nudge-width="200" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" color="primary">
                <v-icon>mdi-account</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-text-field
                  v-model="searchResource"
                  append-icon="mdi-magnify"
                  label="Filtrer par ressource"
                  hide-details
                  solo
                  dense
                  @click:append="searchResourceFunc"
                  @keyup.enter="searchResourceFunc"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-menu>
          <!-- btn de recherche par mois -->
          <MonthSelector @month-selected="handleMonthSelection" />
        </div>
        <div
          v-for="resource in filteredResources"
          :key="'resource-name-' + resource.id"
          class="resource-name"
        >
          <div
            class="avatar"
            :style="{ backgroundColor: getAvatarColor(resource.name) }"
          >
            {{ resource.initials }}
          </div>
          <div class="name-role">
            <div class="name">{{ resource.name }}</div>
          </div>
        </div>
      </div>
      <div class="calendar-grid">
        <div class="grid-header">
          <div class="months-header">
            <div
              v-for="(month, monthIndex) in visibleMonths"
              :key="'month-' + monthIndex"
              class="month-header"
            >
              {{ formatMonth(month) }}
            </div>
          </div>
          <div class="days-header">
            <div
              v-for="(day, index) in flattenedDays"
              :key="'day-header-' + index"
              class="day-header"
              :class="{
                today: isToday(day.date),
                weekend: isWeekend(day.date),
              }"
            >
              <span v-if="index % 7 === 0" class="week-number"
                >W{{ getWeekNumber(day.date) }}</span
              >
              <span class="day-name">{{ formatDay(day.date, "ddd") }}</span>
              <span class="day-number">{{ formatDay(day.date, "D") }}</span>
            </div>
          </div>
        </div>
        <div
          class="resources-grid"
          ref="resourcesGrid"
          @scroll="onResourcesGridScroll"
        >
          <div
            v-for="resource in filteredResources"
            :key="'resource-' + resource.id"
            class="resource-row"
            :style="{ height: calculateRowHeight(resource) + 'px' }"
          >
            <!-- Cellules pour chaque jour -->
            <div
              v-for="(day, dayIndex) in flattenedDays"
              :key="'day-' + resource.id + '-' + dayIndex"
              class="day-cell"
              :class="{
                weekend: isWeekend(day.date),
                today: isToday(day.date),
                past: isPast(day.date),
              }"
              @dblclick="onCellDoubleClick(resource, day.date, $event)"
              @drop="onDrop($event, resource, day.date)"
              @dragover.prevent
              @dragenter="onDragEnter(day.date)"
              @mousemove="onCellMouseMove(day.date, $event)"
              @mouseup="onCellMouseUp"
            >
              <div class="day-cell-content">
                <div class="resource-events-container">
                  <div
                    v-for="event in getDayEvents(resource, day.date)"
                    :key="getEventKey(event) + '-' + day.date"
                    class="event"
                    :style="getEventStyleWithStack(event, resource, day.date)"
                    @mouseenter="showTooltip(event, $event)"
                    @mouseleave="hideTooltip"
                    :draggable="true"
                    @dragstart="onDragStart($event, event)"
                    @dragend="event.dragendListener"
                  >
                    <span class="event-title">{{ event.carte.libelle }}</span>
                    <!-- <span class="event-hours" v-if="event.carte.duree">
                      {{ event.carte.duree.duree }}h
                    </span> -->
                  </div>
                </div>
              </div>

              <!-- Jauge de charge -->
              <div
                v-if="getDailyWorkload(resource, day.date).totalHours > 0"
                class="workload-gauge"
                :class="
                  getWorkloadClass(
                    getDailyWorkload(resource, day.date).totalHours,
                    getDailyWorkload(resource, day.date).limit
                  )
                "
              >
                <div
                  class="gauge-bar"
                  :style="{
                    width:
                      getWorkloadPercentage(
                        getDailyWorkload(resource, day.date).totalHours,
                        getDailyWorkload(resource, day.date).limit
                      ) + '%',
                  }"
                ></div>
                <div class="gauge-text">
                  {{ getDailyWorkload(resource, day.date).totalHours }}/
                  {{ getDailyWorkload(resource, day.date).limit }}h
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="tooltipVisible"
      class="tooltip"
      :style="{ top: tooltipY + 'px', left: tooltipX + 'px' }"
    >
      <div>
        <strong>{{ tooltipEvent.carte.libelle }}</strong>
      </div>
      <div>Type: {{ tooltipEvent.carte.impactType }}</div>
      <!-- duree -->
      <div>Début: {{ formatDate(tooltipEvent.carte.assignement.duree) }}</div>
      <div>
        Début: {{ formatDate(tooltipEvent.carte.assignement.dateDebut) }}
      </div>
      <div>Fin: {{ formatDate(tooltipEvent.carte.assignement.dateFin) }}</div>
      <div>Origine: {{ tooltipEvent.carte.originType }}</div>
    </div>

    <!-- Modal d'alerte de dépassement -->
    <v-dialog v-model="showLimitAlert" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Dépassement de la limite quotidienne</v-card-title
        >
        <v-card-text>
          <p>
            Cette assignation dépasserait la limite quotidienne de
            {{ ressourceLimite }} heures.
          </p>
          <p>Heures déjà planifiées: {{ heuresPlanifiees }}h</p>
          <p>Heures de la nouvelle tâche: {{ nouvellesHeures }}h</p>
          <p>Total: {{ heuresPlanifiees + nouvellesHeures }}h</p>

          <v-expansion-panels v-if="datesSuggestions.length">
            <v-expansion-panel>
              <v-expansion-panel-header>
                Voir les dates disponibles suggérées
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list dense>
                  <v-list-item
                    v-for="(date, index) in datesSuggestions"
                    :key="index"
                    @click="planifierADate(date)"
                  >
                    <v-list-item-content>
                      {{ formatDate(date) }} - Charge:
                      {{
                        getDailyWorkload(ressourceDestination, date).totalHours
                      }}h
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="autoriserDepassement">
            Autoriser le dépassement
          </v-btn>
          <v-btn color="primary" text @click="showLimitAlert = false">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal pour la création de tâche -->
    <v-dialog v-model="showCreateTaskModal" max-width="800px">
      <CreateDevWithAssignement
        v-if="showCreateTaskModal"
        :preselectedUser="selectedResourceForTask"
        :preselectedDateRange="selectedDateRange"
        @task-created="onTaskCreated"
        @close="showCreateTaskModal = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import "moment/locale/fr";
import draggable from "vuedraggable";
import DeveloppementService from "@/Services/SupportVision/DeveloppementService";
import CreateDevWithAssignement from "./createDevWithAssignement.vue";
import UserService from "@/Services/UserService";
import ClientService from "@/Services/SupportVision/ClientService";
import BasesService from "@/Services/SupportVision/BasesService";
import { mapState, mapMutations } from "vuex";
import MonthSelector from "./monthSelector.vue";
import debounce from "lodash/debounce";

moment.locale("fr");

export default {
  name: "PlanningRessources",
  components: {
    draggable,
    CreateDevWithAssignement,
    MonthSelector,
  },
  props: {
    taskSupportVision: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentDate: new Date(),
      zoomLevel: 1,
      visibleWeeks: [],
      visibleMonths: [],
      isScrolling: false,
      isDragging: false,
      tooltipEvent: null,
      tooltipVisible: false,
      tooltipX: 0,
      tooltipY: 0,
      dragEndDate: null,
      currentView: "month",
      selectedProject: "all",
      resourceView: "people",
      selectionStart: null,
      selectionEnd: null,
      selectedResource: null,
      showTaskModal: false,
      showCreateTaskModal: false,
      selectedDateRange: null,
      selectedResourceForTask: null,
      searchResource: "",
      isSelecting: false,
      selectionStyle: {
        position: "absolute",
        backgroundColor: "rgba(123, 31, 162, 0.2)", // Couleur violette semi-transparente
        border: "1px solid rgb(123, 31, 162)",
        pointerEvents: "none",
        zIndex: 1,
      },
      selectionElement: null,
      showLimitAlert: false,
      taskEnCours: null,
      dateDestination: null,
      ressourceDestination: null,
      ressourceLimite: 7,
      heuresPlanifiees: 0,
      nouvellesHeures: 0,
      datesSuggestions: [],
      workloadCache: {},
      eventsCache: {},
      updateScheduled: false,
      dragCache: null,
    };
  },
  computed: {
    resources() {
      return this.users.map((user) => ({
        id: user.id,
        name: `${user.firstname} ${user.lastname}`,
        initials: `${user.firstname[0]}${user.lastname[0]}`,
      }));
    },
    flattenedDays() {
      return this.visibleWeeks.flat();
    },
    // selectionStyle() {
    //   return this.calculateSelectionStyle();
    // },
    filteredResources: {
      get() {
        if (!this.searchResource) {
          return this.resources;
        }
        const searchTerm = this.searchResource.toLowerCase();
        return this.resources.filter((resource) =>
          resource.name.toLowerCase().includes(searchTerm)
        );
      },
      set(value) {
        // Cette méthode set est nécessaire si vous voulez pouvoir
        // assigner directement à filteredResources
        // Vous pouvez laisser vide si vous n'en avez pas besoin
      },
    },
  },
  methods: {
    ...mapMutations(["setClientList", "setBaseList"]),
    handleMonthSelection({ year, month }) {
      // Mettre à jour la date courante avec l'année et le mois sélectionnés
      this.currentDate = moment(`${year}-${month}-01`, "YYYY-M-DD").toDate();

      // Mettre à jour la vue du calendrier
      this.updateVisibleWeeks();
      this.updateVisibleMonths();

      // Forcer la mise à jour du composant
      this.$forceUpdate();
    },
    // Ajoutez cette méthode dans la section methods du composant
    getChargeJournaliere(resource, date) {
      try {
        // S'assurer que date est un objet moment
        const targetDate = moment(date).startOf("day");

        // Filtrer les événements pour ce jour et cette ressource
        const events = this.taskSupportVision.filter((task) => {
          if (
            !task.carte ||
            !task.carte.assignement ||
            !task.carte.assignement.dateDebut ||
            !task.carte.assignement.dateFin ||
            !task.carte.duree
          ) {
            return false;
          }

          const taskStart = moment(task.carte.assignement.dateDebut).startOf(
            "day"
          );
          const taskEnd = moment(task.carte.assignement.dateFin).startOf("day");

          return (
            task.carte.assignement.assignedTo === resource.name &&
            moment(targetDate).isBetween(taskStart, taskEnd, "day", "[]")
          );
        });

        // Calculer la charge totale
        const chargeTotal = events.reduce((total, task) => {
          if (!task.carte.duree || !task.carte.duree.duree) return total;

          const taskStart = moment(task.carte.assignement.dateDebut);
          const taskEnd = moment(task.carte.assignement.dateFin);
          const durationInDays = taskEnd.diff(taskStart, "days") + 1;

          // Si la tâche s'étend sur plusieurs jours, répartir les heures
          const hoursPerDay = Number(task.carte.duree.duree) / durationInDays;

          return total + hoursPerDay;
        }, 0);

        // Arrondir à une décimale
        return Math.round(chargeTotal * 10) / 10;
      } catch (error) {
        console.error("Erreur dans getChargeJournaliere:", error);
        return 0;
      }
    },
    getDailyWorkload(resource, date) {
      try {
        const cacheKey = `${resource.id}-${moment(date).format("YYYY-MM-DD")}`;
        if (this.workloadCache[cacheKey]) {
          return this.workloadCache[cacheKey];
        }

        const dayStart = moment(date).startOf("day");
        const dayEnd = moment(date).endOf("day");

        const events = this.getEventsForResource(resource).filter((task) => {
          if (
            !task.carte?.assignement?.dateDebut ||
            !task.carte?.assignement?.dateFin ||
            !task.carte?.duree
          ) {
            return false;
          }

          const taskStart = moment(task.carte.assignement.dateDebut);
          const taskEnd = moment(task.carte.assignement.dateFin);

          return (
            taskStart.isSameOrBefore(dayEnd) && taskEnd.isSameOrAfter(dayStart)
          );
        });

        const totalHours = events.reduce((total, task) => {
          if (!task.carte.duree) return total;

          const taskStart = moment(task.carte.assignement.dateDebut);
          const taskEnd = moment(task.carte.assignement.dateFin);

          if (
            taskStart.isSame(dayStart, "day") &&
            taskEnd.isSame(dayEnd, "day")
          ) {
            return total + Number(task.carte.duree.duree);
          }

          const durationInDays = taskEnd.diff(taskStart, "days") + 1;
          const hoursPerDay = Number(task.carte.duree.duree) / durationInDays;

          return total + hoursPerDay;
        }, 0);

        const roundedTotal = Math.round(totalHours * 10) / 10;
        let limit = this.ressourceLimite;

        const taskWithLimit = events.find(
          (task) => task.carte.assignement.ressources?.limiteQuotidienne
        );

        if (taskWithLimit?.carte.assignement.ressources) {
          limit = taskWithLimit.carte.assignement.ressources.limiteQuotidienne;
        }

        const result = {
          totalHours: roundedTotal,
          limit,
          details: events.map((task) => ({
            id: task._id,
            title: task.carte.libelle,
            hours: task.carte.duree ? task.carte.duree.duree : 0,
            type: task.carte.impactType,
          })),
        };

        this.workloadCache[cacheKey] = result;
        return result;
      } catch (error) {
        console.error("Erreur dans getDailyWorkload:", error);
        return {
          totalHours: 0,
          limit: this.ressourceLimite,
          details: [],
        };
      }
    },

    getWorkloadPercentage(hours, limit) {
      return Math.min((hours / limit) * 100, 100);
    },

    getWorkloadClass(hours, limit) {
      const percentage = (hours / limit) * 100;
      if (percentage <= 50) return "workload-low";
      if (percentage <= 75) return "workload-medium";
      if (percentage <= 90) return "workload-high";
      return "workload-critical";
    },

    updateTracking(resource, date, hours) {
      if (!this.trackingDebounceTimer) {
        this.trackingDebounceTimer = setTimeout(async () => {
          try {
            const events = this.getEventsForResource(resource);
            const existingEvent = events.find((event) =>
              moment(event.carte.assignement.dateDebut).isSame(date, "day")
            );

            if (existingEvent?.carte.assignement.ressources) {
              let tracking =
                existingEvent.carte.assignement.ressources.tracking || [];
              const dateStr = moment(date).format("YYYY-MM-DD");
              const existingIndex = tracking.findIndex(
                (t) => t.date === dateStr
              );

              if (existingIndex === -1) {
                tracking.push({
                  date: dateStr,
                  heures: hours,
                  timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
                });
              } else {
                tracking[existingIndex] = {
                  ...tracking[existingIndex],
                  heures: hours,
                  timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
                };
              }

              await DeveloppementService.updateTaskTracking(existingEvent._id, {
                tracking,
              });

              existingEvent.carte.assignement.ressources.tracking = tracking;
            }
          } catch (error) {
            console.error("Erreur lors de la mise à jour du tracking:", error);
            this.$nError("Erreur lors de la mise à jour du tracking");
          } finally {
            this.trackingDebounceTimer = null;
          }
        }, 5000); // Délai de 5 secondes
      }
    },
    searchResourceFunc() {
      // Réinitialiser la vue du calendrier si nécessaire
      this.updateVisibleWeeks();
      this.updateVisibleMonths();

      // Filtrer les ressources
      const searchTerm = this.searchResource.toLowerCase();
      this.filteredResources = this.resources.filter((resource) => {
        resource.name.toLowerCase().includes(searchTerm);
      });

      // Mettre à jour l'affichage du calendrier
      this.$nextTick(() => {
        // Recalculer la hauteur des lignes du calendrier si nécessaire
        this.recalculateCalendarHeight();

        // Faire défiler jusqu'à la première ressource correspondante
        if (this.filteredResources.length > 0) {
          const firstMatchingResource = this.filteredResources[0];
          const resourceElement = this.$el.querySelector(
            `[data-resource-id="${firstMatchingResource.id}"]`
          );
          if (resourceElement) {
            resourceElement.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }
        }
      });
    },
    recalculateCalendarHeight() {
      const calendarContainer = this.$el.querySelector(".calendar-container");
      const resourceNames = this.$el.querySelector(".resource-names");
      const calendrierRessource = this.$refs.calendrierRessources;

      // Si la recherche est vide, réinitialiser à la hauteur par défaut
      if (this.searchResource === "") {
        calendrierRessource.style.height = "calc(100vh - 170px)";
        return;
      }
      switch (this.filteredResources.length) {
        case 0:
          break;
        case 1:
          // calendarContainer.style.height = 200 + 'px';
          // resourceNames.style.height = 150 + 'px';
          calendrierRessource.style.height = 200 + "px";
          break;
        case 2:
          // calendarContainer.style.height = 400 + 'px';
          // resourceNames.style.height = 400 + 'px';
          calendrierRessource.style.height = 260 + "px";
          break;
        case 3:
          // calendarContainer.style.height = 600 + 'px';
          // resourceNames.style.height = 600 + 'px';
          calendrierRessource.style.height = 325 + "px";
          break;
        case 4:
          // calendarContainer.style.height = 800 + 'px';
          // resourceNames.style.height = 800 + 'px';
          calendrierRessource.style.height = 360 + "px";
          break;
        case 5:
          // calendarContainer.style.height = 1000 + 'px';
          // resourceNames.style.height = 1000 + 'px';
          calendrierRessource.style.height = 380 + "px";
          break;
        case 6:
          // calendarContainer.style.height = 450 + 'px';
          // resourceNames.style.height = 450 + 'px';
          calendrierRessource.style.height = 450 + "px";
          break;
        default:
          break;
      }
    },
    formatMonth(date) {
      return moment(date).format("MMM YYYY");
    },
    formatDay(date, format) {
      return moment(date).format(format);
    },
    formatEventTime(event) {
      return moment(event.carte.assignement.dateDebut).format("HH:mm");
    },
    changeDate(delta) {
      this.currentDate = moment(this.currentDate)
        .add(delta, this.currentView)
        .toDate();
      this.updateVisibleWeeks();
      this.updateVisibleMonths();
    },
    goToToday() {
      // Obtenir la date d'aujourd'hui
      const today = moment();

      // Définir la date courante sur aujourd'hui
      this.currentDate = today.toDate();

      // Vider les caches
      this.clearCaches();

      // Mettre à jour la vue
      this.updateVisibleWeeks();
      this.updateVisibleMonths();

      // Faire défiler jusqu'à aujourd'hui si nécessaire
      this.$nextTick(() => {
        const todayCell = this.$el.querySelector(".day-cell.today");
        const resourcesGrid = this.$refs.resourcesGrid;

        if (todayCell && resourcesGrid) {
          todayCell.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
        }
      });
    },
    updateVisibleWeeks() {
      try {
        let start;
        let totalDays;
        const today = moment();
        let startOfMonth;

        switch (this.currentView) {
          case "day":
            start = moment(this.currentDate);
            totalDays = 1;
            break;
          case "week":
            start = moment(this.currentDate).startOf("week");
            totalDays = 7;
            break;
          case "month":
          default:
            startOfMonth = moment(this.currentDate).startOf("month");
            start = moment(this.currentDate);
            totalDays = 42;
            break;
        }

        const days = new Array(totalDays).fill(null).map((_, index) => ({
          date: moment(start)
            .add(index, "days")
            .toDate(),
        }));

        // Création des semaines
        const weeks = [];
        for (let i = 0; i < days.length; i += 7) {
          if (this.currentView === "day") {
            weeks.push([days[i]]);
          } else {
            weeks.push(days.slice(i, i + 7));
          }
        }

        this.visibleWeeks = weeks;
        this.updateVisibleMonths();
      } catch (error) {
        console.error("Erreur dans updateVisibleWeeks:", error);
      }
    },

    // Fonction utilitaire pour diviser un tableau en morceaux
    chunk(array, size) {
      const chunked = [];
      for (let i = 0; i < array.length; i += size) {
        chunked.push(array.slice(i, i + size));
      }
      return chunked;
    },
    updateVisibleMonths() {
      if (!this.visibleWeeks.length || !this.visibleWeeks[0].length) return;

      const start = moment(this.visibleWeeks[0][0].date).startOf("month");
      const end = moment(
        this.visibleWeeks[this.visibleWeeks.length - 1][0].date
      ).endOf("month");

      this.visibleMonths = [];
      let month = start.clone();

      while (month.isSameOrBefore(end, "month")) {
        this.visibleMonths.push(month.toDate());
        month.add(1, "month");
      }

      // Ajuster la largeur des en-têtes de mois
      this.$nextTick(() => {
        const monthHeaders = this.$el.querySelectorAll(".month-header");
        const daysInMonth = {};
        const cellWidth =
          this.$el.querySelector(".day-header")?.offsetWidth || 60;

        this.visibleWeeks.flat().forEach((day) => {
          const monthKey = moment(day.date).format("YYYY-MM");
          daysInMonth[monthKey] = (daysInMonth[monthKey] || 0) + 1;
        });

        monthHeaders.forEach((header, index) => {
          const monthKey = moment(this.visibleMonths[index]).format("YYYY-MM");
          const width = (daysInMonth[monthKey] || 0) * cellWidth;
          header.style.width = `${width}px`;
          header.style.minWidth = `${width}px`;
          header.style.flex = `0 0 ${width}px`;
        });
      });
    },
    getWeekNumber(date) {
      return moment(date).week();
    },
    isWeekend(date) {
      return moment(date).day() === 0 || moment(date).day() === 6;
    },
    isToday(date) {
      return moment(date).isSame(moment(), "day");
    },
    isPast(date) {
      return moment(date).isBefore(moment(), "day");
    },
    getAvatarColor(name) {
      let hash = 0;
      for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
      }
      const color = `hsl(${hash % 360}, 70%, 50%)`;
      return color;
    },
    getColorByDemande(demande) {
      const colors = {
        "Travaux unique": "#43A047",
        Release: "#1E88E5",
        Urgent: "#E53935",
        "Time Off": "#757575",
        "PLANETE-POKE": "#8BC34A",
        CARTE: "#FF9800",
        DIFFUSION: "#9C27B0",
        TOPS: "#00BCD4",
      };
      return colors[demande] || "#7b4e8e";
    },
    getEventsForResource(resource) {
      if (!this.visibleWeeks.length || !this.visibleWeeks[0].length) {
        return [];
      }

      const cacheKey = `${resource.id}-${moment(this.currentDate).format(
        "YYYY-MM-DD"
      )}`;
      if (this.eventsCache[cacheKey]) {
        return this.eventsCache[cacheKey];
      }

      const startDate = moment(this.visibleWeeks[0][0].date).startOf("day");
      const endDate = moment(
        this.visibleWeeks[this.visibleWeeks.length - 1][
          this.visibleWeeks[this.visibleWeeks.length - 1].length - 1
        ].date
      ).endOf("day");

      const filteredEvents = this.taskSupportVision
        .filter(
          (task) =>
            task.carte?.assignement?.assignedTo === resource.name &&
            task.carte?.assignement?.dateDebut &&
            task.carte?.assignement?.dateFin &&
            moment(task.carte.assignement.dateFin).isSameOrAfter(startDate) &&
            moment(task.carte.assignement.dateDebut).isSameOrBefore(endDate)
        )
        .map((task) => ({
          ...task,
          dragendListener: (e) => this.onDragEnd(e),
        }));

      this.eventsCache[cacheKey] = filteredEvents;
      return filteredEvents;
    },
    getEventStyle(event) {
      const startDate = moment(event.carte.assignement.dateDebut);
      const endDate = moment(event.carte.assignement.dateFin);
      const startIndex = this.getDayIndex(startDate);
      const endIndex = this.getDayIndex(endDate);
      const totalDays = this.flattenedDays.length;

      const left = (startIndex / totalDays) * 100 + "%";
      const width = ((endIndex - startIndex + 1) / totalDays) * 100 + "%";

      return {
        left: left,
        width: width,
        backgroundColor: this.getColorByDemande(event.carte.impactType),
      };
    },
    getEventKey(event) {
      // Générer une clé unique basée sur plusieurs propriétés de l'événement
      return `event-${event._id || ""}-${event.carte.libelle}-${
        event.carte.assignement.dateDebut
      }-${event.carte.assignement.dateFin}`;
    },
    onDragStart(event, taskEvent) {
      if (event?.dataTransfer) {
        this.isDragging = true;
        document.body.style.cursor = "grabbing";

        // Stocker l'information dans le cache local
        this.dragCache = {
          taskId: taskEvent._id,
          originalResource: taskEvent.carte.assignement.assignedTo,
          originalStartDate: taskEvent.carte.assignement.dateDebut,
          originalEndDate: taskEvent.carte.assignement.dateFin,
          duration:
            moment(taskEvent.carte.assignement.dateFin).diff(
              moment(taskEvent.carte.assignement.dateDebut),
              "days"
            ) + 1,
        };

        // Stocker minimum d'info dans dataTransfer
        event.dataTransfer.setData("text/plain", taskEvent._id);
      }
    },

    async onDropok(event, resource, date) {
      try {
        if (!this.dragCache) return;

        const taskId = event.dataTransfer.getData("text/plain");
        if (taskId !== this.dragCache.taskId) return;

        const taskData = this.taskSupportVision.find(
          (task) => task._id === taskId
        );
        if (!taskData) return;

        const newStartDate = moment(date).startOf("day");
        const duration = moment.duration(
          moment(taskData.carte.assignement.dateFin).diff(
            moment(taskData.carte.assignement.dateDebut)
          )
        );
        const newEndDate = moment(date)
          .startOf("day")
          .add(duration);

        // Vérifier la charge de manière optimisée
        const chargeJournaliere = this.calculateChargeJournaliere(
          resource,
          date
        );
        const nouvellesHeures = taskData.carte.duree?.duree || 0;

        if (chargeJournaliere + nouvellesHeures > this.ressourceLimite) {
          // Réinitialiser les datesSuggestions
          this.datesSuggestions = [];

          // Mettre à jour les données pour la modale
          this.taskEnCours = taskData;
          this.dateDestination = date;
          this.ressourceDestination = resource;
          this.heuresPlanifiees = chargeJournaliere;
          this.nouvellesHeures = nouvellesHeures;

          // Rechercher les dates disponibles
          const suggestionsDates = this.findDatesDisponibles(
            resource,
            nouvellesHeures
          );

          // Mettre à jour les datesSuggestions de manière réactive
          this.$set(this, "datesSuggestions", suggestionsDates);

          // Afficher la modale
          this.showLimitAlert = true;
          return;
        }

        const updatedTask = {
          ...taskData,
          carte: {
            ...taskData.carte,
            assignement: {
              ...taskData.carte.assignement,
              assignedTo: resource.name,
              dateDebut: newStartDate.toDate(),
              dateFin: newEndDate.toDate(),
              dateAssignement: moment().toDate(),
            },
          },
        };

        // Mise à jour locale optimisée
        const taskIndex = this.taskSupportVision.findIndex(
          (t) => t._id === taskId
        );
        if (taskIndex !== -1) {
          this.$set(this.taskSupportVision, taskIndex, updatedTask);
        }

        // Sauvegarder en base de données
        await this.saveTaskToDB(updatedTask);
        this.$nInfo("Tâche assignée avec succès");

        // Nettoyer les caches
        this.clearCaches();
        this.dragCache = null;

        // Mise à jour différée du calendrier
        requestAnimationFrame(() => {
          this.updateCalendarView();
        });
      } catch (error) {
        console.error("Erreur lors de l'assignation:", error);
        this.$nError("Erreur lors de l'assignation de la tâche");
      } finally {
        this.isDragging = false;
        document.body.style.cursor = "default";
      }
    },
    onDragEnter(date) {
      if (!this.isDragging) return;
      this.dragEndDate = date;
    },

    onDragEnd() {
      this.isDragging = false;
      document.body.style.cursor = "default";
      this.dragCache = null;
    },
    getEventStyleWithStack(event, resource, date) {
      const startDate = moment(event.carte.assignement.dateDebut);
      const endDate = moment(event.carte.assignement.dateFin);
      const currentDate = moment(date);
      const isFirstDay = startDate.isSame(currentDate, "day");
      const isLastDay = endDate.isSame(currentDate, "day");

      // Calcul de la position verticale
      const eventsOnSameDay = this.getDayEvents(resource, date);
      const eventIndex = eventsOnSameDay.findIndex((e) => e._id === event._id);
      const topOffset = eventIndex * 24; // 24px par événement

      return {
        backgroundColor: this.getColorByDemande(event.carte.impactType),
        position: "absolute",
        height: "22px",
        top: `${topOffset}px`,
        left: "1px",
        right: "1px",
        borderRadius: "2px",
        zIndex: 1,
        borderTopLeftRadius: isFirstDay ? "2px" : "0",
        borderBottomLeftRadius: isFirstDay ? "2px" : "0",
        borderTopRightRadius: isLastDay ? "2px" : "0",
        borderBottomRightRadius: isLastDay ? "2px" : "0",
      };
    },
    calculateRowHeight(resource) {
      const maxOverlap = this.getEventsForResource(resource).reduce(
        (max, event) => {
          const overlappingEvents = this.getEventsForResource(resource).filter(
            (e) => {
              const eventStart = moment(event.carte.assignement.dateDebut);
              const eventEnd = moment(event.carte.assignement.dateFin);
              const eStart = moment(e.carte.assignement.dateDebut);
              const eEnd = moment(e.carte.assignement.dateFin);
              return (
                e._id !== event._id &&
                !(eEnd.isBefore(eventStart) || eStart.isAfter(eventEnd))
              );
            }
          );
          return Math.max(max, overlappingEvents.length + 1);
        },
        1
      );

      // Increased base height from 60 to 80, and event height from 24 to 30
      return Math.max(80, maxOverlap * 30 + 20); // 30px per event + 20px for the gauge
    },
    getDayEvents(resource, date) {
      return this.getEventsForResource(resource).filter((event) => {
        const eventStart = moment(event.carte.assignement.dateDebut).startOf(
          "day"
        );
        const eventEnd = moment(event.carte.assignement.dateFin).endOf("day");
        const cellDate = moment(date).startOf("day");
        return cellDate.isBetween(eventStart, eventEnd, "day", "[]");
      });
    },

    calculateChargeJournaliere(resource, date) {
      const cacheKey = `${resource.id}-${moment(date).format("YYYY-MM-DD")}`;
      if (this.workloadCache[cacheKey]) {
        return this.workloadCache[cacheKey].totalHours;
      }

      const events = this.getEventsForResource(resource).filter((event) =>
        moment(event.carte.assignement.dateDebut).isSame(date, "day")
      );

      const total = events.reduce(
        (sum, event) => sum + (event.carte.duree?.duree || 0),
        0
      );

      this.workloadCache[cacheKey] = { totalHours: total };
      return total;
    },

    findDatesDisponibles(resource, heuresNecessaires) {
      const suggestions = [];
      let dateTest = moment(this.dateDestination);

      for (let i = 1; i <= 30 && suggestions.length < 5; i++) {
        const testDate = moment(dateTest)
          .add(i, "days")
          .startOf("day");

        // Vérifier si le jour est un weekend
        if (this.isWeekend(testDate.toDate())) {
          continue; // Sauter les weekends
        }

        const eventsForDay = this.getDayEvents(resource, testDate.toDate());
        const chargeJour = eventsForDay.reduce((total, event) => {
          return total + (event.carte?.duree?.duree || 0);
        }, 0);

        if (chargeJour + heuresNecessaires <= this.ressourceLimite) {
          suggestions.push(testDate.toDate());
        }
      }

      return suggestions;
    },

    async autoriserDepassement() {
      await this.procederAssignation(
        this.taskEnCours,
        this.ressourceDestination,
        this.dateDestination
      );
      this.showLimitAlert = false;
    },

    async planifierADate(nouvelleDate) {
      try {
        // Procéder à l'assignation
        await this.procederAssignation(
          this.taskEnCours,
          this.ressourceDestination,
          nouvelleDate
        );

        // Fermer la modale
        this.showLimitAlert = false;

        // Nettoyer les états
        this.taskEnCours = null;
        this.dateDestination = null;
        this.ressourceDestination = null;
        this.datesSuggestions = [];

        // Vider les caches
        this.clearCaches();

        // Rafraîchir les données depuis le serveur
        await this.refreshCalendarData();

        // Forcer la mise à jour de la vue
        this.$nextTick(() => {
          this.updateCalendarView();
          this.$forceUpdate();
        });
      } catch (error) {
        console.error("Erreur lors de la planification:", error);
        this.$nError("Erreur lors de la planification de la tâche");
      }
    },

    async procederAssignation(task, resource, date) {
      try {
        // Calculer la durée en jours de la tâche originale
        const originalDuration = moment(task.carte.assignement.dateFin).diff(
          moment(task.carte.assignement.dateDebut),
          "days"
        );

        // Définir la nouvelle date de début au début de la journée
        const newStartDate = moment(date).startOf("day");

        // Calculer la nouvelle date de fin en ajoutant la durée originale
        const newEndDate = moment(date)
          .startOf("day")
          .add(originalDuration, "days")
          .endOf("day");

        const updatedTask = {
          ...task,
          carte: {
            ...task.carte,
            assignement: {
              ...task.carte.assignement,
              assignedTo: resource.name,
              dateDebut: newStartDate.toDate(),
              dateFin: newEndDate.toDate(),
              dateAssignement: moment().toDate(),
            },
          },
        };

        // Enregistrer dans la base de données
        await this.saveTaskToDB(updatedTask);

        // Mettre à jour l'état local
        const taskIndex = this.taskSupportVision.findIndex(
          (t) => t._id === task._id
        );
        if (taskIndex !== -1) {
          this.$set(this.taskSupportVision, taskIndex, updatedTask);
        }

        // Forcer la mise à jour de la vue
        this.clearCaches();
        this.updateCalendarView();

        this.$nInfo("Tâche assignée avec succès");

        return updatedTask;
      } catch (error) {
        console.error("Erreur lors de l'assignation:", error);
        this.$nError("Erreur lors de l'assignation de la tâche");
        throw error;
      }
    },

    saveTaskToDB: debounce(async function(task) {
      try {
        const dataToSend = {
          assignement: {
            assigned: true,
            assignedTo: task.carte.assignement.assignedTo,
            dateDebut: moment(task.carte.assignement.dateDebut).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            dateFin: moment(task.carte.assignement.dateFin).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            dateAssignement: moment().format("YYYY-MM-DD HH:mm:ss"),
          },
        };

        const response = await DeveloppementService.assignTask(
          task._id,
          dataToSend
        );
        return response;
      } catch (error) {
        console.error("Erreur lors de la sauvegarde:", error);
        throw error;
      }
    }, 0),
    async refreshCalendarData() {
      try {
        // Récupérer les données à jour depuis le serveur
        const response = await DeveloppementService.getDev();

        if (response?.data) {
          // Mettre à jour les données locales
          this.$store.commit("setTaskSupportVision", response.data);

          // Vider les caches
          this.workloadCache = {};
          this.eventsCache = {};
          this.dragCache = null;

          // Mettre à jour la vue du calendrier
          this.updateVisibleWeeks();
          this.updateVisibleMonths();

          // Force le re-rendu
          this.$nextTick(() => {
            this.$forceUpdate();
          });
        }
      } catch (error) {
        console.error("Erreur lors du rafraîchissement des données:", error);
        this.$nError("Erreur lors du rafraîchissement des données");
      }
    },

    onResourcesGridScroll(event) {
      if (!this.isScrolling) {
        this.isScrolling = true;
        this.$refs.resourceNames.scrollTop = event.target.scrollTop;
        this.$nextTick(() => {
          this.isScrolling = false;
        });
      }
    },
    showTooltip(event, e) {
      this.tooltipEvent = event;
      this.tooltipVisible = true;
      this.tooltipX = e.clientX + 10;
      this.tooltipY = e.clientY + 10;
    },
    hideTooltip() {
      this.tooltipVisible = false;
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    onCellDoubleClick(resource, date, event) {
      // Arrêter la propagation pour éviter que le clic global ne déclenche resetSelection
      event?.stopPropagation();

      const cellRect = event.target.getBoundingClientRect();
      const clickX = event.clientX - cellRect.left;
      const cellWidth = cellRect.width;
      const dayFraction = clickX / cellWidth;

      const clickTime = moment(date)
        .startOf("day")
        .add(dayFraction * 24, "hours");

      // Reset toute sélection existante avant d'en créer une nouvelle
      this.resetSelection();

      this.selectionStart = clickTime.toDate();
      this.selectionEnd = this.selectionStart;
      this.selectedResource = resource;
      this.isSelecting = true;

      // Créer et ajouter l'élément de sélection
      this.createSelectionElement(event.target);

      document.addEventListener("mousemove", this.onDocumentMouseMove);
      document.addEventListener("mouseup", this.onDocumentMouseUp);
    },
    createSelectionElement(targetCell) {
      if (this.selectionElement) {
        this.selectionElement.remove();
      }

      const resourceRow = targetCell.closest(".resource-row");
      if (!resourceRow) return;

      this.selectionElement = document.createElement("div");
      Object.assign(this.selectionElement.style, this.selectionStyle);
      this.selectionElement.style.height = `${resourceRow.offsetHeight - 2}px`;

      resourceRow.appendChild(this.selectionElement);
      this.updateSelectionPosition();
    },
    onDocumentMouseMove(event) {
      // Empêcher la sélection de texte pendant le drag
      event.preventDefault();
    },
    updateSelectionPosition() {
      if (!this.selectionElement || !this.selectionStart || !this.selectionEnd)
        return;

      const startDay = moment(this.selectionStart).startOf("day");
      const endDay = moment(this.selectionEnd).startOf("day");

      const cellWidth = this.getCellWidth();
      const startIndex = this.getDayIndex(startDay);
      const endIndex = this.getDayIndex(endDay);

      const left = startIndex * cellWidth;
      const width = (endIndex - startIndex + 1) * cellWidth;

      this.selectionElement.style.left = `${left}px`;
      this.selectionElement.style.width = `${width}px`;
    },
    onCellMouseMove(date, event) {
      if (!this.isSelecting) return;

      const cellRect = event.target.getBoundingClientRect();
      const mouseX = event.clientX - cellRect.left;
      const cellWidth = cellRect.width;
      const dayFraction = mouseX / cellWidth;

      const hoverTime = moment(date)
        .startOf("day")
        .add(dayFraction * 24, "hours");

      this.selectionEnd = hoverTime.toDate();
      this.updateSelectionPosition();
    },
    onCellMouseUp(event) {
      if (!this.isSelecting) return;

      // Arrêter la propagation pour éviter que le clic global ne déclenche resetSelection
      event?.stopPropagation();

      this.isSelecting = false;
      if (this.selectedResource && this.selectionStart && this.selectionEnd) {
        this.selectedDateRange = {
          start: moment
            .min(moment(this.selectionStart), moment(this.selectionEnd))
            .toDate(),
          end: moment
            .max(moment(this.selectionStart), moment(this.selectionEnd))
            .toDate(),
        };
        this.selectedResourceForTask = this.selectedResource;
        this.showCreateTaskModal = true;
      }

      document.removeEventListener("mousemove", this.onDocumentMouseMove);
      document.removeEventListener("mouseup", this.onDocumentMouseUp);
    },
    onDocumentMouseUp() {
      this.onCellMouseUp();
    },
    async onTaskCreated(newTask) {
      try {
        // Ajouter la nouvelle tâche à la liste des tâches
        this.taskSupportVision.push(newTask);

        // Forcer la mise à jour de la vue
        this.$nextTick(() => {
          this.closeCreateDevModal();
          this.$nInfo("Nouvelle tâche créée avec succès");
          this.refreshCalendarData();
        });
      } catch (error) {
        console.error("Erreur lors de la création de la tâche:", error);
        this.$nError("Erreur lors de la création de la tâche");
      }
    },
    resetSelection() {
      if (this.selectionElement) {
        this.selectionElement.remove();
        this.selectionElement = null;
      }
      this.selectionStart = null;
      this.selectionEnd = null;
      this.selectedResource = null;
      this.selectedDateRange = null;
      this.selectedResourceForTask = null;
      this.isSelecting = false;
      // Supprimer les écouteurs d'événements s'ils existent encore
      document.removeEventListener("mousemove", this.onDocumentMouseMove);
      document.removeEventListener("mouseup", this.onDocumentMouseUp);
    },
    closeCreateDevModal() {
      this.showCreateTaskModal = false;
      this.resetSelection();
    },
    onCellMouseEnter(date, event) {
      if (!this.selectionStart) return;

      const cellRect = event.target.getBoundingClientRect();
      const mouseX = event.clientX - cellRect.left;
      const cellWidth = cellRect.width;
      const dayFraction = mouseX / cellWidth;

      const hoverTime = moment(date)
        .startOf("day")
        .add(dayFraction * 24, "hours");

      this.selectionEnd = hoverTime.toDate();
    },
    getDayIndex(date) {
      return this.flattenedDays.findIndex((day) =>
        moment(day.date).isSame(date, "day")
      );
    },
    calculateSelectionStyle() {
      if (!this.selectionStart || !this.selectionEnd || !this.selectedResource)
        return {};

      const startDay = moment(this.selectionStart).startOf("day");
      const endDay = moment(this.selectionEnd).startOf("day");

      const startIndex = this.getDayIndex(startDay);
      const endIndex = this.getDayIndex(endDay);

      // Obtenir la largeur réelle d'une cellule
      const cellWidth = this.getCellWidth();

      // Calculer les offsets en tenant compte de l'heure exacte
      const startOffset =
        (this.selectionStart - startDay) / (24 * 60 * 60 * 1000);
      const endOffset = (this.selectionEnd - endDay) / (24 * 60 * 60 * 1000);

      const left = startIndex * cellWidth + startOffset * cellWidth;
      const width =
        (endIndex - startIndex) * cellWidth +
        (endOffset - startOffset) * cellWidth;

      return {
        position: "absolute",
        left: `${left}px`,
        width: `${width}px`,
        height: "100%",
        backgroundColor: "rgba(0, 123, 255, 0.3)",
        pointerEvents: "none",
      };
    },
    getCellWidth() {
      const cell = this.$el.querySelector(".day-cell");
      return cell ? cell.offsetWidth : 40;
    },
    handleGlobalClick(event) {
      // Vérifier si on clique sur une cellule ou sur la zone de sélection
      const isClickOnCell = event.target.closest(".day-cell");
      const isClickOnSelection = event.target.closest(".selection-overlay");

      // Si on ne clique ni sur une cellule ni sur la sélection, on reset
      if (!isClickOnCell && !isClickOnSelection) {
        this.resetSelection();
      }
    },
    async onCarteCreated(carte) {
      try {
        // Étape 2 : Assigner la tâche
        const assignmentData = {
          assignement: {
            assigned: true,
            assignedTo: this.selectedResourceForTask.name,
            dateDebut: moment(this.selectedDateRange.start).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            dateFin: moment(this.selectedDateRange.end).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            dateAssignement: moment().format("YYYY-MM-DD HH:mm:ss"),
          },
        };

        const assignedTask = await DeveloppementService.assignTask(
          carte._id,
          assignmentData
        );

        // Ajouter la tâche assignée à la liste des tâches
        if (assignedTask && assignedTask.data) {
          this.taskSupportVision.push(assignedTask.data);
        }

        this.closeCreateDevModal();
        this.$nInfo("Nouvelle tâche créée et assignée avec succès");
        await this.refreshCalendarData();
      } catch (error) {
        console.error(
          "Erreur lors de la création ou de l'assignation de la tâche:",
          error
        );
        this.$nError(
          "Erreur lors de la création ou de l'assignation de la tâche"
        );
      }
    },
    async onDrop(event, resource, date) {
      try {
        // Vérifier d'abord si c'est un drag depuis une tâche non assignée
        let taskData;
        try {
          const dragData = JSON.parse(event.dataTransfer.getData("text/plain"));
          if (dragData.type === "unassigned-task") {
            taskData = this.taskSupportVision.find(
              (task) => task._id === dragData.taskId
            );
          } else {
            // C'est un drag existant, utiliser le dragCache existant
            if (!this.dragCache) return;
            const taskId = event.dataTransfer.getData("text/plain");
            if (taskId !== this.dragCache.taskId) return;
            taskData = this.taskSupportVision.find(
              (task) => task._id === taskId
            );
          }
        } catch (e) {
          // Si le parse échoue, c'est probablement un drag existant
          if (!this.dragCache) return;
          const taskId = event.dataTransfer.getData("text/plain");
          if (taskId !== this.dragCache.taskId) return;
          taskData = this.taskSupportVision.find((task) => task._id === taskId);
        }

        if (!taskData) return;

        // Le reste de la logique reste identique
        const newStartDate = moment(date).startOf("day");
        const duration = moment.duration(
          moment(taskData.carte.assignement?.dateFin || date).diff(
            moment(taskData.carte.assignement?.dateDebut || date)
          )
        );
        const newEndDate = moment(date)
          .startOf("day")
          .add(duration);

        // Vérification de la charge
        const chargeJournaliere = this.calculateChargeJournaliere(
          resource,
          date
        );
        const nouvellesHeures = taskData.carte.duree?.duree || 0;

        if (chargeJournaliere + nouvellesHeures > this.ressourceLimite) {
          this.datesSuggestions = [];
          this.taskEnCours = taskData;
          this.dateDestination = date;
          this.ressourceDestination = resource;
          this.heuresPlanifiees = chargeJournaliere;
          this.nouvellesHeures = nouvellesHeures;

          const suggestionsDates = this.findDatesDisponibles(
            resource,
            nouvellesHeures
          );
          this.$set(this, "datesSuggestions", suggestionsDates);
          this.showLimitAlert = true;
          return;
        }

        const updatedTask = {
          ...taskData,
          carte: {
            ...taskData.carte,
            assignement: {
              ...taskData.carte.assignement,
              assignedTo: resource.name,
              dateDebut: newStartDate.toDate(),
              dateFin: newEndDate.toDate(),
              dateAssignement: moment().toDate(),
              assigned: true,
            },
          },
        };

        // Mise à jour locale
        const taskIndex = this.taskSupportVision.findIndex(
          (t) => t._id === taskData._id
        );
        if (taskIndex !== -1) {
          this.$set(this.taskSupportVision, taskIndex, updatedTask);
        }

        // Sauvegarde en base
        await this.saveTaskToDB(updatedTask);
        this.$nInfo("Tâche assignée avec succès");

        this.clearCaches();
        this.dragCache = null;

        requestAnimationFrame(() => {
          this.updateCalendarView();
        });
      } catch (error) {
        console.error("Erreur lors de l'assignation:", error);
        this.$nError("Erreur lors de l'assignation de la tâche");
      } finally {
        this.isDragging = false;
        document.body.style.cursor = "default";
      }
    },
    updateCalendarView() {
      this.updateVisibleWeeks();
      this.updateVisibleMonths();
    },
    clearCaches() {
      this.workloadCache = {};
      this.eventsCache = {};
      this.dragCache = null;
    },
    async loadData() {
      this.updateVisibleWeeks();
      this.updateVisibleMonths();
      const clientsList = await ClientService.getClientList();
      const baseList = await BasesService.getBasesList();
      this.$store.commit("setClientList", clientsList);
      this.$store.commit("setBaseList", baseList);
    },
  },
  async mounted() {
    await this.loadData();
    // écouteur pour la touche Echap
    document.addEventListener("keydown", this.handleEscapeKey);
  },
  watch: {
    currentDate() {
      this.clearCaches();
      this.updateCalendarView();
    },
    currentView: {
      handler() {
        this.clearCaches();
        this.updateCalendarView();
      },
      immediate: true,
    },
    taskSupportVision: {
      handler() {
        this.clearCaches();
        if (!this.updateScheduled) {
          this.updateScheduled = true;
          window.requestAnimationFrame(() => {
            this.updateCalendarView();
            this.updateScheduled = false;
          });
        }
      },
      deep: true,
    },
  },
  beforeDestroy() {
    if (this.trackingDebounceTimer) {
      clearTimeout(this.trackingDebounceTimer);
    }
    // Nettoyer l'écouteur quand le composant est détruit
    document.removeEventListener("keydown", this.handleEscapeKey);
  },

  handleEscapeKey(event) {
    if (event.key === "Escape") {
      this.resetSelection();
    }
  },
};
</script>

<style scoped>
/* Base layout */
.calendrier-ressources {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  color: #333;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 170px);
  width: 100%; /* Ajout pour utiliser toute la largeur */
}

/* Header styles */
header {
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

nav span {
  margin-right: 15px;
  cursor: pointer;
  font-size: 14px;
}

.active {
  font-weight: bold;
  color: #7b1fa2;
}

.controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.controls button {
  background-color: #7b1fa2;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
}

.controls button:hover {
  background-color: #6a1b9a;
}

.view-selector {
  display: flex;
  align-items: center;
  gap: 8px;
}

.view-selector select {
  padding: 5px 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 13px;
  cursor: pointer;
}

/* Calendar container - Mise à jour */
.calendar-container {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  width: 100%;
}

/* Resource names column - Mise à jour */
.resource-names {
  width: 200px;
  min-width: 200px;
  flex-shrink: 0;
  overflow-y: auto;
  background-color: #f5f5f5;
  border-right: 1px solid #e0e0e0;
}

.resource-menu {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px;
  min-height: 90px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.resource-name {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  height: 80px;
  background-color: #f5f5f5;
}

.avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  color: white;
  font-weight: bold;
  font-size: 14px;
}

.name-role {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.name {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

/* Calendar grid - Mise à jour */
.calendar-grid {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-width: 0; /* Important pour le flex */
}

.grid-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
}

/* Headers - Mise à jour */
.months-header,
.days-header {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  height: 45px;
  width: 100%;
}

.month-header {
  text-align: center;
  padding: 12px 0;
  border-right: 1px solid #e0e0e0;
  font-weight: 500;
  font-size: 12px;
  color: #666;
  flex: 1;
  min-width: 60px; /* Augmenté de 40px à 60px */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  background-color: white;
}

.day-header {
  flex: 1;
  min-width: 60px; /* Augmenté de 40px à 60px */
  border-right: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  position: relative;
  background-color: white;
  box-sizing: border-box;
}

/* .week-number {
  position: absolute;
  top: 1px;
  left: 1px;
  font-size: 9px;
  color: #999;
  background-color: #f5f5f5;
  padding: 1px 2px;
  border-radius: 2px;
} */

/* .day-name {
  font-size: 10px;
  color: #666;
  text-transform: uppercase;
  margin-bottom: 2px;
} */

/* .day-number {
  font-size: 12px;
  font-weight: bold;
  color: #333;
} */

/* Resources grid - Mise à jour */
.resources-grid {
  flex: 1;
  overflow: auto;
  position: relative;
  width: 100%;
}

.resource-row {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  min-height: 80px;
  width: 100%;
}

.day-cell {
  flex: 1;
  min-width: 60px; /* Augmenté de 40px à 60px */
  border-right: 1px solid #e0e0e0;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

/* Events container and events */
.day-cell-content {
  flex: 1;
  position: relative;
  height: calc(100% - 20px);
}

.resource-events-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.event {
  padding: 2px 4px;
  color: white;
  cursor: move;
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  height: 24px;
  line-height: 20px;
  margin: 1px;
  border-radius: 3px;
  position: absolute;
}

.event:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.event-title {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

/* States */
.weekend {
  background-color: #ebe9e9;
}

.today {
  background-color: #d1c4e9;
}

.past {
  background-color: #fafafa;
  opacity: 0.8;
}
.day-cell.weekend.past {
  background-color: #ebe9e9;
}

/* Workload gauge */
.workload-gauge {
  height: 20px;
  position: relative;
  background-color: #f5f5f5;
  margin-top: auto;
  border-top: 1px solid #e0e0e0;
}

.gauge-bar {
  height: 100%;
  transition: width 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
}

.workload-low .gauge-bar {
  background-color: #4caf50;
}
.workload-medium .gauge-bar {
  background-color: #ff9800;
}
.workload-high .gauge-bar {
  background-color: #f44336;
}
.workload-critical .gauge-bar {
  background-color: #d32f2f;
}

.gauge-text {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 10px;
  color: #666;
  line-height: 20px;
  z-index: 2;
}

/* Tooltip */
.tooltip {
  position: fixed;
  background-color: rgba(33, 33, 33, 0.95);
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 1000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

/* Scrollbar styles */
.resources-grid::-webkit-scrollbar,
.resource-names::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.resources-grid::-webkit-scrollbar-track,
.resource-names::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.resources-grid::-webkit-scrollbar-thumb,
.resource-names::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

/* Responsive styles */
@media (max-width: 1280px) {
  .day-header,
  .month-header,
  .day-cell {
    min-width: 55px; /* Augmenté de 35px à 55px */
  }
}

@media (max-width: 1024px) {
  .day-header,
  .month-header,
  .day-cell {
    min-width: 50px; /* Augmenté de 30px à 50px */
  }
}

@media (max-width: 768px) {
  .day-header,
  .month-header,
  .day-cell {
    min-width: 45px; /* Augmenté de 25px à 45px */
  }
}

/* Ajuster le style des événements pour mieux utiliser l'espace */
.event {
  padding: 2px 6px; /* Augmenté le padding horizontal */
  height: 26px; /* Légèrement plus haut */
  line-height: 22px; /* Ajusté pour le centrage vertical */
  font-size: 12px; /* Légèrement plus grand */
}

/* Ajuster le numéro de semaine pour la nouvelle largeur */
.week-number {
  position: absolute;
  top: 2px;
  left: 2px;
  font-size: 10px;
  color: #999;
  background-color: #f5f5f5;
  padding: 2px 4px;
  border-radius: 3px;
}

/* Ajuster les noms de jours pour la nouvelle largeur */
.day-name {
  font-size: 11px;
  margin-top: 2px;
}

.day-number {
  font-size: 13px;
  margin-top: 2px;
}
</style>
