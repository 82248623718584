import Api from "@/Services/Api";

export default {
    async getDev() {
        let response = await Api().get("/support-vision/developpement")
        return response
    },
    async getDevById(id) {
        let response = await Api().get(`/support-vision/developpement/${id}`)
        return response
    },
    async saveDev(data) {  
        let response = await Api().post(`/support-vision/developpement`, data)
        return response.data
    },
    async assignTask(id, data) {
        let response = Api().post(`/support-vision/developpement/${id}/tache`, data)
        return response
    },
    async unassignTask(id, data) {
        let response = Api().put(`/support-vision/developpement/unassign-task/${id}/tache`, data)
        return response
    },
    async editDev(id, data) {
        let response = await Api().put(`/support-vision/developpement/${id}`, data)
        return response
    },
    async deleteDev(id) {
        let response = await Api().delete(`/support-vision/developpement/${id}`)
        return response
    },
    async statusChange(id, data) {
        let response = await Api().put(`/support-vision/developpement/${id}/status`, data)
        return response
    },
    async resetStatus(id) {
        let response = await Api().put(`/support-vision/developpement/${id}/reset-status`)
        return response
    },
    async addDuration(id, data) {
        let response = await Api().put(`/support-vision/developpement/${id}/add-duration`,{ data: data })
        return response
    },
    //addDatePrevisionnel
    async addDatePrevisionnel(id, data) {
        let response = await Api().put(`/support-vision/developpement/${id}/add-date-previsionnel`,{ data: data })
        return response
    }, 
    async updateHeures(id, data) {
        let response = await Api().put(`/support-vision/developpement/${id}/heures`, data)
        return response
    },
    async updateLimiteQuotidienne(id, data) {
        let response = await Api().put(`/support-vision/developpement/${id}/limite`, data)
        return response
    },
    async getHeuresStats(id) {
        let response = await Api().get(`/support-vision/developpement/${id}/stats`)
        return response
    }, 
    updateTaskTracking(taskId, trackingData) {
        let response = Api().put(`/support-vision/developpement/${taskId}/tracking`, trackingData)
        return response
    }
}