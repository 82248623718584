<template>
  <v-card
    :class="{
      highlighted: card.highlighted,
      'linked-card': isCardLinked(card),
    }"
  >
    <!-- Header de la carte -->
    <div
      class="d-flex align-center justify-space-between pa-0"
      style="cursor: pointer; height: 34px"
      :style="{ backgroundColor: getColorByDemande(card.carte.impactType) }"
    >
      <v-btn icon dark @click.stop="toggleCardShow">
        <v-icon>{{ isCardShown ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
      <div class="white--text searchable text-truncate px-2" style="font-size: 12px">
        {{ card.carte.libelle }}
      </div>
      <v-menu :offset-y="isTermine" :offset-x="!isTermine" top>
        <template v-slot:activator="{ on }">
          <v-btn dark icon v-on="on" @click.stop>
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>
        <v-list v-if="!isTermine">
          <v-list-item @click="$emit('assign-task', card)" v-if="!isCardAssigned(card)">
            <v-icon class="mr-2" color="teal">mdi-account-plus</v-icon>
            <v-list-item-title class="teal--text">Assigner</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('unassign-task', card._id)" v-else>
            <v-icon class="mr-2" color="teal">mdi-account-minus</v-icon>
            <v-list-item-title class="teal--text">Désassigner</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('estimate-duration', card)">
            <v-icon class="mr-2" color="teal">mdi-timer</v-icon>
            <v-list-item-title class="teal--text">Durée estimée</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('date-previsionnel', card)">
            <v-icon class="mr-2" color="teal">mdi-calendar</v-icon>
            <v-list-item-title class="teal--text">Date prévisionnelle</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="$emit('open-detail', card._id)">
            <v-icon class="mr-2" color="indigo">mdi-information</v-icon>
            <v-list-item-title class="indigo--text">Détails</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('update-card', card._id)">
            <v-icon class="mr-2" color="warning">mdi-square-edit-outline</v-icon>
            <v-list-item-title class="warning--text">Modifier</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('delete-card', card._id)">
            <v-icon class="mr-2" color="error">mdi-delete</v-icon>
            <v-list-item-title class="error--text">Supprimer</v-list-item-title>
          </v-list-item>
        </v-list>
        <v-list v-else>
          <v-list-item @click="$emit('reset-status', card._id)">
            <v-icon class="mr-2" color="primary">mdi-undo</v-icon>
            <v-list-item-title class="primary--text">Remettre en cours</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('open-detail', card._id)">
            <v-icon class="mr-2" color="indigo">mdi-information</v-icon>
            <v-list-item-title class="indigo--text">Détails</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('delete-card', card._id)">
            <v-icon class="mr-2" color="error">mdi-delete</v-icon>
            <v-list-item-title class="error--text">Supprimer</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <!-- Contenu de la carte -->
    <v-expand-transition>
      <v-card-text v-if="isCardShown" @click.stop="$emit('open-chat', card)">
        <v-row dense align="center" class="mb-1">
          <v-col cols="auto">
            <v-avatar size="24" color="primary">
              <v-icon dark small>mdi-account</v-icon>
            </v-avatar>
          </v-col>
          <v-col class="searchable">
            {{ formatData(card.carte.userCreate) }}
          </v-col>
          <v-col cols="auto">
            <v-icon small color="grey">mdi-calendar</v-icon>
            <span class="caption ml-1 searchable">{{ formatDate(card.createdAt) }}</span>
          </v-col>
        </v-row>

        <v-row dense class="mb-1">
          <v-col cols="6">
            <v-chip x-small label outlined color="primary" class="searchable">
              {{ formatData(card.carte.module) }}
            </v-chip>
          </v-col>
          <v-col cols="6">
            <v-chip x-small label outlined color="secondary" class="searchable">
              {{ formatData(card.carte.sous_module) || "Aucun" }}
            </v-chip>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="6">
            <v-icon small color="info" class="mr-1">mdi-cog</v-icon>
            <span class="caption searchable">{{ card.carte.devType }}</span>
          </v-col>
          <v-col cols="6">
            <v-icon small color="success" class="mr-1">mdi-source-branch</v-icon>
            <span class="caption searchable">{{ card.carte.originType }}</span>
          </v-col>
        </v-row>

        <template v-if="card.carte.clientName">
          <v-row dense>
            <v-col cols="12">
              <v-icon small color="primary" class="mr-1">mdi-account-group</v-icon>
              <span class="caption searchable">{{ card.carte.clientName }}</span>
            </v-col>
          </v-row>
        </template>

        <template v-if="card.carte.base">
          <v-row dense>
            <v-col cols="12">
              <v-icon small color="primary" class="mr-1">mdi-database</v-icon>
              <span class="caption searchable">{{ card.carte.base }}</span>
            </v-col>
          </v-row>
        </template>

        <template v-if="card.carte.assignement && card.carte.assignement.assignedTo">
          <v-row dense>
            <v-col cols="12">
              <v-icon small color="warning" class="mr-1">mdi-account-check</v-icon>
              <span class="caption searchable">
                Assigné à: {{ card.carte.assignement.assignedTo }}
              </span>
            </v-col>
          </v-row>
        </template>

        <template v-if="card.carte.duree">
          <v-row dense>
            <v-col cols="12">
              <v-icon small color="error" class="mr-1">mdi-clock-outline</v-icon>
              <span class="caption searchable">
                Durée: {{ card.carte.duree.duree }} {{ card.carte.duree.unite }}
              </span>
            </v-col>
          </v-row>
        </template>

        <template v-if="card.carte.datePrevisionnel">
          <v-row dense>
            <v-col cols="12">
              <v-icon small color="purple" class="mr-1">mdi-calendar-check</v-icon>
              <span class="caption searchable">
                Prévue: {{ formatDate(card.carte.datePrevisionnel) }}
              </span>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  name: "Carte",
  props: {
    card: {
      type: Object,
      required: true,
    },
    isTermine: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCardShown: false,
    };
  },
  methods: {
    toggleCardShow(event) {
      event.stopPropagation();
      this.isCardShown = !this.isCardShown;
    },
    isCardLinked(card) {
      return (
        card.carte.linkedCard && 
        card.carte.linkedCard !== "null" && 
        card.carte.linkedCard !== ""
      );
    },
    isCardAssigned(card) {
      return card.carte.assignement && card.carte.assignement.assigned;
    },
    getColorByDemande(demande) {
      const colors = {
        "Travaux unique": "#43A047",
        Release: "#1E88E5",
        Urgent: "#E53935",
      };
      return colors[demande] || "#7b4e8e";
    },
    formatData(data) {
      if (Array.isArray(data)) {
        return data.join(", ");
      }
      return data ? data.replace(/[\][\]"]+/g, "") : "";
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  watch: {
    card: {
      immediate: true,
      handler(newCard) {
        this.isCardShown = false;
      }
    }
  }
};
</script>

<style scoped>
.v-card {
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.highlighted {
  border: 2px solid var(--v-primary-base);
}

.linked-card {
  border-left: 4px solid var(--v-warning-base);
}

.searchable {
  word-break: break-word;
}

.v-card:hover {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}

.v-expand-transition-enter-active,
.v-expand-transition-leave-active {
  transition: all 0.3s ease-in-out;
}

.caption {
  font-size: 0.75rem !important;
}

/* Amélioration de l'apparence des chips */
.v-chip.v-chip--outlined {
  border-width: 1px;
}

/* Style pour le menu */
.v-list-item {
  min-height: 35px !important;
}

.v-list-item__title {
  font-size: 0.875rem;
}
</style>