<template>
  <v-container fluid>
    <v-toolbar rounded class="mb-2">
      <v-toolbar-title class="text-h5" elevation="20">
        Développement
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- ajouter un menu pour mettre mes boutton dedans -->
      <v-menu offset-y max-width="250" v-if="isRights">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list min-width="250px">
          <v-list-item @click="openModalCreate">
            <v-list-item-title class="purple--text text--darken-3"
              >Demande de développement</v-list-item-title
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-title class="purple--text text--lighten-2"
              >A voir</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>

    <!-- Nouvelle section pour les tâches non assignées -->
    <v-card class="mb-4">
      <v-card-title class="d-flex align-center">
        Tâches non assignées
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Rechercher"
          single-line
          hide-details
          dense
          class="ml-4"
          style="max-width: 300px"
        ></v-text-field>
      </v-card-title>

      <v-card-text>
        <div class="unassigned-tasks-container">
          <div
            v-for="task in filteredUnAssignedTask"
            :key="task._id"
            class="task-card"
            draggable="true"
            @dragstart="handleDragStart($event, task)"
            @dragend="handleDragEnd"
          >
            <v-chip
              :color="getColorByDevType(task.carte.devType)"
              dark
              small
              class="mb-1"
            >
              {{ task.carte.devType }}
            </v-chip>
            <v-chip
              :color="getColorByDemande(task.carte.impactType)"
              dark
              small
              class="mb-1"
            >
              {{ task.carte.impactType }}
            </v-chip>
            <div class="task-title">{{ task.carte.libelle }}</div>
            <div class="task-details">
              <small>{{ task.carte.module }}</small>
              <small v-if="task.carte.sous_module"
                >- {{ formatData(task.carte.sous_module) }}</small
              >
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <AllYears
      :taskSupportVision="cards"
      @task-updated="handleTaskUpdate"
      :users="users"
      @card-dropped="onCardDropped"
    />
    <!-- modale de creation -->
    <v-dialog v-model="createDev" max-width="50%">
      <CreateDev @close-create="createDev = false" @refresh="loadData" />
    </v-dialog>

    <!-- modale de liste des demandes -->
    <v-dialog v-model="modalListeDemande" max-width="90%">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Liste des demandes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="modalListeDemande = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- trier par impact desc -->
        <v-data-table
          :headers="headers"
          :items="taskSupportVision"
          :items-per-page="5"
          sort-by="carte.impactType"
          sort-desc
          class="elevation-1"
        >
          <!-- si non assigné afficher non assigné -->
          <template v-slot:item.carte.assignement.assignedTo="{ item }">
            {{
              item.carte.assignement
                ? item.carte.assignement.assignedTo
                : "Non assigné"
            }}
          </template>

          <!-- afficher la duree au bon format avec filtre -->
          <template v-slot:item.carte.assignement.duree="{ item }">
            <p v-if="item.carte.assignement && item.carte.assignement.duree">
              {{ item.carte.assignement.duree | formatDuration }}
            </p>
            <p v-else>
              Non assigné
            </p>
          </template>

          <!-- afficher dans v-chip de couleur les type d impact -->
          <template v-slot:item.carte.impactType="{ item }">
            <v-chip
              :color="getColorByDemande(item.carte.impactType)"
              dark
              small
            >
              {{ item.carte.impactType }}
            </v-chip>
          </template>

          <template v-slot:item.carte.assignement.status="{ item }">
            <v-chip
              v-if="item.carte.assignement && item.carte.assignement.status"
              :color="getColorByStatus(item.carte.assignement.status)"
              dark
              small
            >
              {{ item.carte.assignement.status }}
            </v-chip>
            <v-chip v-else color="black" dark small>
              Aucun
            </v-chip>
          </template>

          <!-- si pas de module afficher aucun -->
          <!-- <template v-slot:item.carte.module="{ item }">
            {{ item.carte.module.length > 0 ? item.carte.module.join() : 'Aucun' }}
          </template> -->

          <!-- si pas de sous module afficher aucun -->
          <template v-slot:item.carte.sous_module="{ item }">
            {{ item.carte.sous_module && formatData(item.carte.sous_module) }}
          </template>

          <template v-slot:item.action="{ item }">
            <div class="d-flex">
              <!-- unassign task -->
              <v-btn
                v-if="
                  item.carte.assignement && item.carte.assignement.assignedTo
                "
                icon
                small
                color="primary"
                dark
                @click="unassignTask(item._id)"
              >
                <v-icon>mdi-account-minus</v-icon>
              </v-btn>
              <v-btn
                icon
                small
                color="warning"
                dark
                @click="openModalUpdate(item._id)"
              >
                <v-icon>mdi-square-edit-outline</v-icon>
              </v-btn>
              <v-btn
                icon
                small
                color="error"
                dark
                @click="openModalDelete(item._id)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <!-- modale de modification -->
    <v-dialog v-model="modaleUpdate" max-width="50%">
      <EditDev @close-edit="modaleUpdate = false" @refresh="loadData" />
    </v-dialog>
    <!-- modale de suppression -->
    <v-dialog v-model="modaleDelete" max-width="30%">
      <DeleteDev
        @close-delete-modal="modaleDelete = false"
        @dev-deleted="loadData"
      />
    </v-dialog>
    <!-- carte details -->
    <v-dialog v-model="modaleDetails" max-width="50%">
      <CarteDetails @close="modaleDetails = false" @refresh="loadData" />
    </v-dialog>

    <!-- Dialog pour le chat -->
    <v-dialog v-model="carteChat" max-width="60%">
      <CarteChat
        @close="carteChat = false"
        :card="selectedCard"
        :users="users"
        :location="location"
        @refresh="loadData"
      />
    </v-dialog>
  </v-container>
</template>
<script>
import UserService from "@/Services/UserService";
import * as Vuex from "vuex";
import moment from "moment";
import BasesService from "@/Services/SupportVision/BasesService";
import DeveloppementService from "@/Services/SupportVision/DeveloppementService";
import AllYears from "./allYears.vue";
import Carte from "../Cartes/carte.vue";
import ParametrageService from "@/Services/SupportVision/ParametrageService";

export default {
  name: "Index",
  components: {
    ClientPickerSelect: () =>
      import("@/Components/Commons/Inputs/Client/ClientPickerSelect"),
    CreateDev: () =>
      import("@/Components/Views/SupportVision/Cartes/createDev"),
    Calendar: () =>
      import("@/Components/Views/SupportVision/Developpement/calendar"),
    EditDev: () => import("@/Components/Views/SupportVision/Cartes/editDev"),
    DeleteDev: () =>
      import("@/Components/Views/SupportVision/Developpement/devDelete"),
    CarteDetails: () =>
      import("@/Components/Views/SupportVision/Cartes/carteDetails.vue"),
    AllYears,
    Carte,
    CarteChat: () =>
      import("@/Components/Views/SupportVision/Cartes/carteChat"),
  },
  data() {
    return {
      createDev: false,
      cards: [],
      users: [],
      usersSelected: [],
      selected: false,
      dateDebut: "",
      dateFin: "",
      selectedEvent: {},
      modalListeDemande: false,
      colorByDevType: null,
      headers: [
        { text: "Libellé", value: "carte.libelle", width: "15%" },
        {
          text: "Assigné",
          value: "carte.assignement.assignedTo",
          width: "15%",
        },
        { text: "Durée", value: "carte.assignement.duree", width: "15%" },
        { text: "Impact", value: "carte.impactType" },
        {
          text: "Statut",
          value: "carte.assignement.status",
          sortable: false,
          width: "15%",
        },
        { text: "Type de développement", value: "carte.devType", width: "10%" },
        { text: "Origine", value: "carte.originType", width: "10%" },
        { text: "Module", value: "carte.module", width: "10%" },
        { text: "Sous module", value: "carte.sous_module", width: "15%" },
        {
          text: "Action",
          value: "action",
          sortable: false,
          align: "center",
          width: "10%",
        },
      ],
      modaleUpdate: false,
      modaleDelete: false,
      rights: [],
      modaleDetails: false,
      cardShow: {},
      search: "",
      // carte
      assignTaskDialog: false,
      estimateDurationDialog: false,
      datePrevisionnelDialog: false,
      selectedCard: null,
      selectedUser: null,
      assignmentDateDebut: null,
      assignmentDateFin: null,
      estimatedDuration: null,
      timeDebutMenu: false,
      timeFinMenu: false,
      dateDebutMenu: false,
      dateFinMenu: false,
      timeDebut: null,
      timeFin: null,
      durationUnit: "heures",
      previsionnelDate: null,
      carteChat: false,
      location: "",
    };
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    formatDuration: function(value) {
      if (value) {
        moment.locale("fr");
        return moment.duration(value).humanize();
      }
    },
  },
  computed: {
    ...Vuex.mapMutations(["setTaskSupportVision", "setTaskId", "setRightsDev"]),
    ...Vuex.mapState(["taskSupportVision", "informations"]),
    getColorByDemande() {
      return (demande) => {
        const colors = {
          "Travaux unique": "#43A047",
          Release: "#1E88E5",
          Urgent: "#E53935",
        };
        return colors[demande] || "#7b4e8e";
      };
    },
    getColorByStatus() {
      return (status) => {
        if (status == "en_cours") {
          return "orange";
        } else if (status == "terminé") {
          return "green";
        } else if (status == "prise_en_compte") {
          return "grey";
        } else if (status == undefined || status == "") {
          return "black";
        }
      };
    },
    // retoune les taches non assigné
    unAssignedTask() {
      if (!this.cards) return [];

      return this.cards.filter((item) => {
        try {
          return (
            !item?.carte?.assignement ||
            item.carte.assignement.assigned === false ||
            !item.carte.assignement.assignedTo
          );
        } catch (error) {
          console.error("Erreur de filtrage pour item:", item);
          return false;
        }
      });
    },
    filteredUnAssignedTask() {
      return (
        this.unAssignedTask?.filter((item) => {
          if (!item?.carte || !item.carte?.libelle) return false;

          const searchTerm = this.search?.toLowerCase() || "";
          try {
            return (
              item.carte.libelle.toLowerCase().includes(searchTerm) ||
              (item.carte.module &&
                item.carte.module.toLowerCase().includes(searchTerm)) ||
              (item.carte.sous_module &&
                item.carte.sous_module.toLowerCase().includes(searchTerm)) ||
              (item.carte.devType &&
                item.carte.devType.toLowerCase().includes(searchTerm)) ||
              (item.carte.originType &&
                item.carte.originType.toLowerCase().includes(searchTerm)) ||
              (item.carte.impactType &&
                item.carte.impactType.toLowerCase().includes(searchTerm))
            );
          } catch (error) {
            console.error("Erreur de filtrage pour item:", item);
            return false;
          }
        }) || []
      );
    },
    isValidAssignTask() {
      return (
        !!this.usersSelected.length &&
        !!this.dateDebut &&
        !!this.dateFin &&
        !!this.selectedEvent._id
      );
    },
    isRights() {
      let userMail = this.informations.emailAddress;
      let callCenterMail = [];
      let sysAdminMail = [];
      let directionMail = [];
      let equipeTechniqueMail = [];
      this.rights.map((role) => {
        callCenterMail = role.params.callCenterMail;
        sysAdminMail = role.params.sysAdminMail;
        directionMail = role.params.directionMail;
        equipeTechniqueMail = role.params.equipeTechniqueMail;
      });
      let emailAuthor =
        sysAdminMail.includes(userMail) || directionMail.includes(userMail);
      // commit les droits dans le store
      this.$store.commit("setRightsDev", emailAuthor);
      return emailAuthor;
    },
  },
  methods: {
    openModalCreate() {
      this.createDev = true;
    },
    openModalUpdate(id) {
      this.$store.commit("setTaskId", id);
      this.modaleUpdate = true;
    },
    openDetail(id) {
      this.$store.commit("setTaskId", id);
      this.modaleDetails = true;
    },
    closeModaleUpdate() {
      this.modaleUpdate = false;
    },
    openModalDelete(id) {
      this.$store.commit("setTaskId", id);
      this.modaleDelete = true;
    },
    closeModaleDelete() {
      this.modaleDelete = false;
    },
    openModalListeDemande() {
      this.modalListeDemande = true;
    },
    resetAssignTask() {
      this.usersSelected = [];
      this.dateDebut = "";
      this.dateFin = "";
    },
    selectCard(item) {
      this.selectedEvent = item;
    },
    toggleCardShow(card) {
      this.$set(this.cardShow, card.carte._id, !this.cardShow[card.carte._id]);
    },
    formatData(data) {
      // si data est un tableau
      if (Array.isArray(data)) {
        return data.join();
      } else {
        return data && data.replace(/[\][\]"]+/g, "");
      }
    },
    // fonction composant avec Carte
    async assignTask() {
      const data = {
        assignement: {
          dateAssignement: moment().format("YYYY-MM-DD HH:mm:ss"),
          assignedTo: this.usersSelected,
          dateDebut: this.dateDebut,
          dateFin: this.dateFin,
          event: this.selectedEvent,
          id: this.selectedEvent._id,
          assigned: true,
        },
      };
      const id = this.selectedEvent._id;
      const res = await DeveloppementService.assignTask(id, data);
      this.resetAssignTask();
      this.loadData();
      this.$nInfo("Tâche assignée avec succès");
    },
    handleDragStart(event, task) {
      const dragData = {
        taskId: task._id,
        type: "unassigned-task",
      };

      event.dataTransfer.setData("text/plain", JSON.stringify(dragData));
      event.target.classList.add("dragging");
    },
    handleDragEnd(event) {
      event.target.classList.remove("dragging");
    },

    openChat(card) {
      this.selectedCard = card;
      this.carteChat = true;
    },

    async onCardDropped({ resource, date, taskId }) {
      try {
        const task = this.unAssignedTask.find((t) => t._id === taskId);
        if (!task) return;

        const assignmentData = {
          assignement: {
            assigned: true,
            assignedTo: resource.name,
            dateDebut: moment(date)
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss"),
            dateFin: moment(date)
              .endOf("day")
              .format("YYYY-MM-DD HH:mm:ss"),
            dateAssignement: moment().format("YYYY-MM-DD HH:mm:ss"),
          },
        };

        await DeveloppementService.assignTask(task._id, assignmentData);
        await this.loadData();
        this.$nInfo("Tâche assignée avec succès");
      } catch (error) {
        console.error("Erreur lors de l'assignation:", error);
        this.$nError("Erreur lors de l'assignation de la tâche");
      }
    },
    async unassignTask(id) {
      const res = await DeveloppementService.unassignTask(id);
      this.loadData();
      this.$nInfo("Tâche désassignée avec succès");
    },
    handleTaskUpdate(updatedTask) {
      this.loadData();
    },
    async getColorByDevType() {
      const res = await ParametrageService.getParametrages();
      const colorArray = [];
      // this.colorByDevType = res?.data.map((i) => i.parametrage.couleur);
      // res?.data.map((i) => colorArray.push(i.parametrage.couleur, i.parametrage.choix));
      // avoir cette structure {devType: couleur}
      res?.data.map((i) => (colorArray[i.parametrage.choix] = i.parametrage.couleur));
      
      
    },
    async loadData() {
      const res = await DeveloppementService.getDev();
      this.$store.commit("setTaskSupportVision", res.data);
      this.cards = this.$store.state.taskSupportVision;
      await UserService.getUsers().then((res) => {
        this.users = res;
      });
      this.rights = await BasesService.getParams();
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>

<style scoped>
.disabled-button {
  pointer-events: none;
  opacity: 0.5;
}
.autocomplete-max-height {
  max-height: 50px;
  overflow-y: auto;
}
.biais-trait {
  position: relative;
}

.biais-trait::after {
  content: "";
  position: absolute;
  bottom: 5px;
  right: 13px; /* Ajustez la position du trait */
  width: 2px; /* Longueur du trait */
  height: 20px;
  background-color: gray; /* Couleur du trait */
  transform: skewX(-50deg); /* Inclinaison du trait */
}
.biais-trait::before {
  content: "";
  position: absolute;
  bottom: 3px;
  right: 10px;
  width: 2px;
  height: 14px;
  background-color: gray;
  transform: skewX(-50deg);
}
.unassigned-tasks-container {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 8px;
  min-height: 100px;
  max-height: 200px;
  overflow-y: auto;
}

.task-card {
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px;
  width: 250px;
  cursor: move;
  transition: all 0.2s;
}

.task-card:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-color: #7b1fa2;
}

.task-title {
  font-weight: 500;
  margin: 4px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.task-details {
  color: #666;
  font-size: 0.85em;
}

.task-card.dragging {
  opacity: 0.5;
  transform: scale(0.95);
}
</style>
